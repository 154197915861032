.app-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 3rem;
  overflow-y: auto;
}

.app-content {
  flex-grow: 1;
  padding: 1.5rem 3.5rem;
}
