.nested-grid-content {
  .side-panel {
    display: flex;

    &-nav {
      list-style-type: none;
      margin: 0 0 0.5rem 0;
      padding: 0;
      width: 100%;

      li {
        margin: 0.5rem;
      }
    }
  }

  .form-panel {
    padding: 0.5rem 0;
  }
}

.form-template-buttons {
  display: flex;
  gap: 0.25rem;
  margin-left: auto;
  width: fit-content;
}

.form-section-divider {
  padding: 1rem 0;
}
