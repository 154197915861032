@use '@symplr-ux/alloy-theme/dist/scss' as symplr;

@import '@symplr-ux/alloy-theme/dist/fonts/lato.css';
@import '@symplr-ux/alloy-icons/dist/icons.css';

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;

  > .app-content {
    margin-top: 56px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
